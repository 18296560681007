//////////////////////////////////////////////////////
// Program search bar functionality
//////////////////////////////////////////////////////

function programSearchBarFunctionality() {
  //search functionality
  //grabs all elements for search tag use through data attr.
  const courseSearchBar = document.getElementById("search-bar");
  const courses = document.querySelectorAll(".course");
  const coursesArr = Array.from(courses);
  const noMatchWarning = document.querySelector(".no-matches").parentElement;
  const whiteListBreak = document.querySelector(".list-break");
  const loadingSymbol = document.querySelector(".loading-symbol");
  const courseObjects = [];
  const tags = [];

  //grabs every data attr. representing a tag
  for (let i = 0; i < courses.length; i++) {
    courseObjects[i] = {
      tags: courses[i].getAttribute("data-search-tag").split(","),
    };
  }

  //set debounce function for performance on input
  const debounce = (callBack, delay = 300) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callBack(...args);
      }, delay);
    };
  };

  //function that loops through search terms
  const searchTagsByInput = debounce(() => {
    for (let i = 0; i < courseObjects.length; i++) {
      for (let j = 0; j < courseObjects[i].tags.length; j++) {
        if (
          courseObjects[i].tags[j].includes(
            courseSearchBar.value.trim().toLowerCase()
          )
        ) {
          courses[i].style.display = "block";
          break;
        } else {
          courses[i].style.display = "none";
        }
      }
    }

    //remove loading symbol active class
    loadingSymbol.classList.remove("active");

    //no matches message
    let result = coursesArr.every((course) => {
      if (course.style.display === "none") {
        return true;
      }
    });

    if (result) {
      whiteListBreak.style.display = "none";
      noMatchWarning.style.display = "block";
    } else {
      whiteListBreak.style.display = "block";
      noMatchWarning.style.display = "none";
    }
  }, 400);

  //search algo for search bar
  courseSearchBar.addEventListener("input", () => {
    //add loading symbol active class
    loadingSymbol.classList.add("active");

    //search function with debounce
    searchTagsByInput();
  });

  //reset search button
  const resetBtn = document.getElementById("reset");

  resetBtn.addEventListener("click", () => {
    courses.forEach((course) => {
      course.style.display = "block";
    });
    whiteListBreak.style.display = "block";
    noMatchWarning.style.display = "none";
  });
}

// exports function to be used on other scripts
export default programSearchBarFunctionality;
