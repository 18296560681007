//////////////////////////////////////////////////////
// Course selector javascript for mobile functionality
//////////////////////////////////////////////////////

function courseSelectorMobile() {
  // All variables needed for program search
  const programSearchArea = document.querySelector(".programs__search-area");
  const filterButton = document.querySelector(".filter-control");
  const programChoices = document.querySelectorAll(".program-choice");
  let body = document.querySelector("body");

  //expands and closes/scrolls filter section if mobile program selector is active.
  filterButton.addEventListener("click", () => {
    programSearchArea.classList.toggle("expanded");
    body.classList.toggle("stop-scroll");
  });

  //scrolls to program choice on load in menu
  window.addEventListener("load", () => {
    programChoices.forEach((programChoice) => {
      if (programChoice.parentElement.classList.contains("selected")) {
        programSearchArea.scrollTo({
          top: programChoice.offsetTop - 200,
          behavior: "smooth",
        });
      }
    });
  });

  //array of all program choices
  programChoices.forEach((programChoice) => {
    programChoice.addEventListener("click", (event) => {
      //scroll filter bar when closing for reopening if 1370px screen or smaller.
      if (screen.width <= 1370) {
        programSearchArea.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }

      //prevents re-load if program is already selected (contains the selected CSS class)
      if (programChoice.parentElement.classList.contains("selected")) {
        event.preventDefault();
        event.stopPropagation();
        return;
      }

      //remove 'selected' class from all program choices when another one is clicked or pressed.
      for (let i = 0; i < programChoices.length; i++) {
        programChoices[i].parentElement.classList.remove("selected");
      }

      //closes filter bar, removes stop scroll from body element, adds selected class to click program.
      programSearchArea.classList.remove("expanded");
      body.classList.remove("stop-scroll");
      programChoice.parentElement.classList.add("selected");
    });
  });
}

// exports function to be used on other scripts
export default courseSelectorMobile;
