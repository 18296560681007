class ProgSearch extends HTMLElement {
    connectedCallback() {
      this.innerHTML = `
      <!-- program search -->
      <section class="programs__search-area">
        <div class="degree-section">
          <div class="flex-row filter-control">
            <h3>Select A Program</h3>
            <i class="fas fa-filter"></i>
          </div>
          <div class="degree-section__header flex-column">
            <h3>Your future awaits you...</h3>
            <form onsubmit="return false;">
              <div class="degree-section__search-bar flex-row-between">
                <input
                  id="search-bar"
                  class="search-bar"
                  type="text"
                  placeholder="search your program"
                  maxlength="100"
                />
                <div class="loading-symbol"></div>
              </div>
              <button id="reset" class="btn btn-secondary" type="reset">
                reset
              </button>
            </form>
          </div>
          <div class="scroll">
            <ul class="for-search">
              <li
                data-search-tag="workforce, readiness, workforce readiness, career, training, corporate, development, certificate, online, qrt, iwt, health, connect, hodges, hodges connect, english, grammar, english grammar online, english grammar"
                class="course dropdown-btn"
              >
                <a class="program-choice" href="./prog-workforce-readiness.html"
                  >Workforce Readiness</a
                >
                <div class="dropdown-content">
                  <ul>
                    <li>
                      <a class="program-choice" href="./prog-online-certificates.html"
                        >Online Certificates</a
                      >
                    </li>
                    <li>
                      <a class="program-choice" href="./prog-healthcare-certificates.html"
                        >Healthcare Certificates</a
                      >
                    </li>
                    <li>
                      <a class="program-choice" href="./prog-corporate-training.html"
                        >Corporate Training</a
                      >
                    </li>
                    <li>
                      <a class="program-choice" href="./prog-workforce-esl.html"
                        >English as a Second Language</a
                      >
                    </li>
                  </ul>
                </div>
              </li>
              
              <li class="list-break"></li>

              <li
                data-search-tag="asociados, online degrees, online, degree, degrees, associates, programs, associates programs, asociados"
                class="course"
              >
                <a class="program-choice" href="./prog-associate-degrees.html"
                  >Associate Degrees</a
                >
              </li>
  
              <li
                data-search-tag="titulos profesionales, licenciaturas, online degrees, online, degree, degrees, bachelor's, programs, bachelor's programs, bachelors programs"
                class="course"
              >
                <a class="program-choice" href="./prog-bachelors-degrees.html"
                  >Bachelor's Degrees</a
                >
              </li>
              <li
                data-search-tag="mestrias, online degrees, online, degree, degrees, masters, master's, programs, masters programs, master's programs"
                class="course"
              >
                <a class="program-choice" href="./prog-masters-degrees.html"
                  >Master's Degrees</a
                >
              </li>
              <li
                data-search-tag="programas de certificación, certificación, certificate programs, certificate, programs, undergrad, graduate, certs, certifications"
                class="course"
              >
                <a class="program-choice" href="./prog-certificate-programs.html"
                  >Certificate Programs</a
                >
              </li>

              <li class="list-break"></li>

              <li
                data-search-tag="empresas, negocios, business, math, numbers, financial, finance, management, taxes, accounting, marketing, leadership"
                class="course"
              >
                <a class="program-choice" href="./prog-business-finance-programs.html"
                  >Business and Finance Programs</a
                >
              </li>
              <li
                data-search-tag="ciencias de la salud, health, sciences, medical, dental, science, paramedic, emt, dental hygiene, pta, physical therapy, counseling, mental health, psychology"
                class="course"
              >
                <a class="program-choice" href="./prog-health-sciences-programs.html"
                  >Health Sciences Programs</a
                >
              </li>
              <li
                data-search-tag="estudios jurídicos, legal, lawyer, studies, paralegal, juris, juris master, law, law program, law degree, law school, legal studies"
                class="course"
              >
                <a class="program-choice" href="./prog-legal-studies-programs.html"
                  >Legal Studies Programs</a
                >
              </li>
              <li
                data-search-tag="informática y tecnología de la información, tecnología, coding, it, technology, information, computer science, science, computer, cybersecurity, database management, digital design, graphic design, business technology, design technology"
                class="course"
              >
                <a class="program-choice" href="./prog-technology-programs.html"
                  >Technology Programs</a
                >
              </li>
              <li
                data-search-tag="estudios generales, general, studies, general studies, estudios interdisciplinarios, interdisciplinary studies, interdisciplinary, studies, inglés como segundo idioma, esl, speaking, english, spanish, as, a, second, language, english as a second language, learn english, gramática inglesa en línea, ego, english, speaking, english, spanish"
                class="course"
              >
                <a class="program-choice" href="./prog-general-studies-programs.html"
                  >General Studies Programs</a
                >
              </li>

              <li class="list-break"></li>

              <li
                data-search-tag="carreras en linea, titulos, online degrees, online, degree, degrees, bachelor's, master's, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-online-degrees.html"
                  >Online Degrees</a
                >
              </li>
              <li
                data-search-tag="hodges en directo, hodges direct, hodges, direct, hodge's, hodge's direct"
                class="course"
              >
                <a class="program-choice" href="./prog-hu-direct.html"
                  >Hodges Direct</a
                >
              </li>
              <li
                data-search-tag="de por vida, educacion permanente, aprendizaje, life, long, learning, elderly, life long learning"
                class="course"
              >
                <a class="program-choice" href="./prog-lifelong-learning.html"
                  >Lifelong Learning</a
                >
              </li>

              <li class="list-break"></li>

              <li
                data-search-tag="contabilidad, legal, accounting, law, taxes, associate's, bachelor's"
                class="course"
              >
                <a class="program-choice" href="./prog-accounting.html"
                  >Accounting</a
                >
              </li>
              <li
                data-search-tag="psicología aplicada, applied, psychology, mental, health, applied psychology, mental health, counseling"
                class="course"
              >
                <a class="program-choice" href="./prog-applied-psychology.html"
                  >applied psychology</a
                >
              </li>
              <li
                data-search-tag="enfermería, bsn, nursing, medical, bachelor's, health, bachelors, science, of, bachelor's of science in nursing"
                class="course"
              >
                <a class="program-choice" href="./prog-bsn.html">BSN (Nursing)</a>
              </li>
              <li
                data-search-tag="empresas, negocios, business, math, numbers, financial, finance, management, taxes"
                class="course"
              >
                <a class="program-choice" href="./prog-business.html">business</a>
              </li>
              <li
                data-search-tag="consejería clínica de salud mental, clínica, cmhc, clinical, mental health, mental, health, counseling, counselor, clinical mental health counciling, associates"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-clinical-mental-health-counseling.html"
                  >clinical mental health counseling</a
                >
              </li>
              <li
                data-search-tag="informática y tecnología de la información, tecnología, coding, it, technology, information, computer science, science, computer, bachelors, masters, master's, science, bachelor's"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-computer-science-and-it.html"
                  >Computer Science</a
                >
              </li>
              <li
                data-search-tag="higiene dental, higiene, dental, hygiene, medical, health, dental hygiene, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-dental-hygiene.html"
                  >dental hygiene</a
                >
              </li>
              <li
                data-search-tag="diseño digital y gráfico, gráfico, diseño, tecnología, digital, design, graphics, and, digital design and graphics, graphic design, associate's, bachelor's, associates"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-digital-design-and-graphics.html"
                  >Digital Design and Graphics</a
                >
              </li>
              <li
                data-search-tag="servicios médicos de urgencia, emergencia, emergency medical technician, emt, medical, health, bachelors, science, hodges connect, workforce readiness"
                class="course"
              >
                <a class="program-choice" href="./prog-emt.html"
                  >EMT (Emergency Medical Technician)</a
                >
              </li>
              <li
                data-search-tag="gramática inglesa en línea, ego, english, speaking, english, spanish, hodges connect, hodges, connect, workforce readiness"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-english-grammar-online.html"
                  >English Grammar online</a
                >
              </li>
              <li
                data-search-tag="inglés como segundo idioma, esl, speaking, english, spanish, as, a, second, language, english as a second language, learn english"
                class="course"
              >
                <a class="program-choice" href="./prog-esl.html"
                  >English As a Second Language</a
                >
              </li>
              <li
                data-search-tag="finanzas, business, math, management, financial, finance, masters, master's, bachelors, bachelor's, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-finance.html">finance</a>
              </li>
           
              <li
                data-search-tag="estudios generales, general, studies, general studies, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-aa-general-studies.html"
                  >General Studies</a
                >
              </li>
  
              <li
                data-search-tag="ciencias de la salud, health, sciences, medical, dental, bachelors, bachelor's, science, paramedic"
                class="course"
              >
                <a class="program-choice" href="./prog-health-sciences.html"
                  >Health sciences</a
                >
              </li>
              <li
                data-search-tag="estudios interdisciplinarios, interdisciplinary studies, interdisciplinary, studies, associate's, bachelor's"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-interdisciplinary-studies.html"
                  >interdisciplinary Studies</a
                >
              </li>
              <li
                data-search-tag="estudios jurídicos, legal, lawyer, juris, master, juris master, master's"
                class="course"
              >
                <a class="program-choice" href="./prog-juris-master.html"
                  >Juris Master</a
                >
              </li>
              <li
                data-search-tag="estudios jurídicos, legal, lawyer, studies, bachelors, bachelor's"
                class="course"
              >
                <a class="program-choice" href="./prog-legal-studies.html"
                  >legal studies</a
                >
              </li>
  
              <li
                data-search-tag="comercialización contemporáneo, mercadeo, bachelor's, modern, marketing, modern marketing, online, bachelors, associates, associate's"
                class="course"
              >
                <a class="program-choice" href="./prog-modern-marketing.html"
                  >Modern Marketing</a
                >
              </li>
              <li
                data-search-tag="gerencia, administración, direccion, business, math, management, financial, finance, masters, master's"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-organizational-leadership.html"
                  >Organizational Leadership</a
                >
              </li>
              <li
                data-search-tag="gerencia, administración, direccion, business, math, management, financial, finance, bachelors, bachelor's"
                class="course"
              >
                <a
                  class="program-choice"
                  href="./prog-organizational-management.html"
                  >Organizational Management</a
                >
              </li>
              <li
                data-search-tag="estudios jurídicos, legal, lawyer, studies, paralegal, paralegal studies, associates, associate's"
                class="course"
              >
                <a class="program-choice" href="./prog-paralegal.html"
                  >Paralegal studies</a
                >
              </li>
              <li
                data-search-tag="servicios médicos de urgencia, emergencia, paramedic, paramedic certificate, medical, health, bachelors, science, hodges connect, workforce readiness"
                class="course"
              >
                <a class="program-choice" href="./prog-paramedic.html"
                  >Paramedic Certificate</a
                >
              </li>
  
              <li
                data-search-tag="asistente de fisioterapia, pta, nurse, nursing, clinical, physical, therapy, assistant, medical, physical therapy, physical therapist, physical therapy assistant, therapist, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-pta.html"
                  >physical therapist Assistant</a
                >
              </li>
              <li
                data-search-tag="enfermería, bsn, nursing, medical, bachelor's, health, bachelors, science, of, bachelor's of science in nursing, rn, rn to bsn, rn bsn"
                class="course"
              >
                <a class="program-choice" href="./prog-rn-bsn.html">RN-BSN</a>
              </li>
              <li
                data-search-tag="-, pre, dental, pre-dental, pre dental, hygiene, medical, health, dental hygiene, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-pre-dental.html"
                  >pre-dental</a
                >
              </li>
              <li
                data-search-tag="-, pre, nursing, pre-nursing, pre nursing, medical, health, health sciences, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-pre-nursing.html"
                  >Pre-Nursing</a
                >
              </li>
              <li
                data-search-tag="asistente de fisioterapia, pta, nurse, nursing, clinical, physical, therapy, assistant, medical, physical therapy, physical therapist, physical therapy assistant, therapist, associates"
                class="course"
              >
                <a class="program-choice" href="./prog-pre-pta.html"
                  >Pre-PTA</a
                >
              </li>
              <li class="display-none">
                <div class="flex-column width-stretch no-matches">
                  <i class="fas fa-grin-beam-sweat"></i>
                  <h3 class="text-center">Hmm...Lets Try That Again</h3>
                  <p class="text-center">
                    Please click 'Reset' or delete your search and try again.
                  </p>
                </div>
              </li>
              <li class="course-spacer"></li>
            </ul>
          </div>
        </div>
      </section>
      <!-- program search end -->
      `;
      
    }
  }

  customElements.define('prog-search', ProgSearch);
