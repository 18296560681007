//sass goes here
import "../scss/program.scss";

//
//imported logic goes here
//

//Global elements
import "./js-reusable/header"
import "./js-reusable/gdpr"
import "./js-reusable/footer"
import "./js-reusable/progsearch" 


//YouTube Facade
import '@justinribeiro/lite-youtube';

//nav bar functionality
import navBarFunc from "./js-comps/navbar";
navBarFunc();

//translation functionality
import translatorFunc from "./js-comps/translator";
translatorFunc();

//gdpr function
import gdprFunctionality from "./js-comps/gdpr";
gdprFunctionality();

//search functionality
import programSearchBarFunctionality from "./js-comps/searchCourse";
programSearchBarFunctionality();

import courseSelectorMobile from "./js-comps/courseSelectorMobile";
courseSelectorMobile();
